<template>
    <div class="e-screen">
        <img src="@/assets/img/questionNew/31.png" class="e-screen-close" @click="close" />
        <div class="e-screen-title">智能练习</div>
        <div class="e-screen-number">
            <div class="number-top">
                <img src="@/assets/img/questionNew/57.png" class="e-screen-img" />
                <div class="e-screen-courseName oneEllipsis">{{ info.kcName }}</div>
                <div class="number-count">
                    <span class="mr10">总题量</span>
                    <span>{{ info.subjectNum }}</span>
                    <span>题</span>
                </div>
                <div class="number-count">
                    <span class="mr10">筛选数量</span>
                    <span>{{ screenNumber }}</span>
                    <span>题</span>
                </div>
            </div>
            <div class="number-bottom">
                <div class="e-progress-box">
                    <div class="e-progress">
                        <div class="e-progress-fill" :style="{ width: info.makerRate + '%' }">
                            <div class="e-progress-fill-text">{{ info.makerSubjectNum }}</div>
                            <img src="@/assets/img/questionNew/58.png" class="e-progress-icon"
                                :style="{ transform: `translateY(-50%) translateX(${info.makerRate == 0 ? '100' : '60'}%)` }"
                                v-if="info.makerRate != 100" />
                        </div>
                        <div class="e-progress-surplus">
                            <span>{{ info.makerNoSubjectNum }}</span>
                        </div>
                    </div>
                </div>
                <span class="e-precentage">{{ info.makerRate }}%</span>
            </div>
        </div>
        <div class="e-screen-list">
            <div class="e-screen-choose" v-for="(item, index) in screenList" :key="index">
                <div class="e-screen-c-title">
                    <span>{{ item.title }}</span>
                    <!-- 刷题题类提示 -->
                    <div class="e-screen-tips-f" v-if="index == 2">
                        <img src="@/assets/img/questionNew/34.png" />
                        <span>注：“全部题类”包含以下及其他所有题类</span>
                    </div>
                    <!-- 刷题模式提示 -->
                    <div class="e-screen-tips-f" v-if="index == 3">
                        <img src="@/assets/img/questionNew/34.png" />
                        <span>注：背题模式不统计刷题量</span>
                    </div>
                </div>
                <div :class="{ 'e-screen-c-item': true, 'e-screen-c-item-check': getScreenCheck(ite, index) }"
                    v-for="(ite, idx) in item.data" :key="idx" :style="{ width: index == 4 ? '78px' : 'auto' }"
                    @click="chooseScreenItem(ite, index)">
                    {{ ite.name }}
                </div>
            </div>
        </div>
        <div class="e-screen-bottom">
            <div @click="goDoQuestion()" class="e-screen-but">开始刷题</div>
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
    props: {
        courseNameId: {
            type: String | Number,
            default: "",
        },
        courseNameInfo: {
            type: Object,
            default: () => ({}),
        },
        // 1智能练习 2做题记录重新做题
        type: {
            type: Number,
            default: 1,
        },
    },
    components: {},
    data() {
        return {
            screenList: [{
                title: "刷题题型",
                data: [
                    {
                        id: -1,
                        name: "全部题型",
                    },
                    {
                        id: 0,
                        name: "单选题",
                    },
                    {
                        id: 1,
                        name: "多选题",
                    },
                    {
                        id: 2,
                        name: "判断题",
                    },
                    {
                        id: 5,
                        name: "组合题",
                    },
                    {
                        id: 6,
                        name: "不定项选择",
                    },
                    {
                        id: 3,
                        name: "填空题",
                    },
                    {
                        id: 4,
                        name: "简答题",
                    },
                ],
            },
            {
                title: "刷题状态",
                data: [
                    {
                        id: 0,
                        name: "全部",
                    },
                    {
                        id: 1,
                        name: "未刷",
                    },
                    {
                        id: 2,
                        name: "已刷",
                    },
                    {
                        id: 3,
                        name: "错题",
                    },
                ],
            },
            {
                title: "刷题题类",
                data: [
                    {
                        id: 0,
                        name: "全部题类",
                    },
                    {
                        id: 7,
                        name: "历年真题",
                    },
                    {
                        id: 5,
                        name: "易错考题",
                    },
                    {
                        id: 6,
                        name: "课程模考",
                    },
                    {
                        id: 4,
                        name: "考前押题",
                    },
                ],
            },
            {
                title: "刷题模式",
                data: [
                    {
                        id: 1,
                        name: "顺序模式",
                    },
                    {
                        id: 3,
                        name: "随机模式",
                    },
                    {
                        id: 2,
                        name: "背题模式",
                    },
                ],
            },
            {
                title: "刷题数量",
                data: [
                    {
                        id: 0,
                        name: "全部",
                    },
                    {
                        id: 5,
                        name: "5",
                    },
                    {
                        id: 10,
                        name: "10",
                    },
                    {
                        id: 15,
                        name: "15",
                    },
                    {
                        id: 20,
                        name: "20",
                    },
                    {
                        id: 30,
                        name: "30",
                    },
                    {
                        id: 40,
                        name: "40",
                    },
                    {
                        id: 50,
                        name: "50",
                    },
                ],
            },], // 试卷列表
            exeInfo: {
                allQuestion: 0,
                screenNumber: 0,
                makerRate: 0,
                brushNumber: 0,
                noAnswerNumber: 0,
            },
            getInfo: null,
            questionType: [-1],
            questionState: 0,
            questionCategory: [0],
            questionMode: 1,
            questionNumber: 10,
            screenNumber: 0,
            questionModeName: '顺序模式',
            info: {}
        }
    },
    async created() {
        this.userInfo = getInfo()
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false)
            return
        }
        await this.getPaperTypeList()

        await this.getQuestionKeCheng()
        await this.selectVipSubjectAnswer()
    },
    methods: {
        goDoQuestion() {
            if (this.screenNumber && this.screenNumber != 0) {
                this.$router.push(`/questionNew/questionAnswer?courseNameId=${this.courseNameId}&questionType=${this.questionType.join(', ')}&questionCategory=${this.questionCategory.join(', ')}&questionMode=${this.questionMode}&questionState=${this.questionState}&questionNumber=${this.questionNumber}&questionModeName=${this.questionModeName}&type=1`)
            } else {
                this.$notify({
                    title: '提示',
                    message: '暂无此类试题哦~',
                    type: 'warning'
                });
            }
        },
        async getQuestionKeCheng() {
            answerClient.getQuestionKeCheng({ kcId: this.courseNameId, userId: this.userInfo.id }).then(res => {
                this.info = res.data
                // this.info.makerRate = 50
            })
        },
        getScreenCheck(ite, index) {
            if (index == 0) {
                // 刷题题型
                return this.questionType.includes(ite.id);
            } else if (index == 1) {
                // 刷题状态
                return this.questionState == ite.id;
            } else if (index == 2) {
                // 刷题题类
                return this.questionCategory.includes(ite.id);
            } else if (index == 3) {
                // 刷题模式

                return this.questionMode == ite.id;
            } else if (index == 4) {
                // 刷题数量
                return this.questionNumber == ite.id;
            }
        },
        async selectVipSubjectAnswer() {
            answerClient.selectVipSubjectAnswer({
                courseId: this.courseNameId, pageSize: this.questionNumber == 0 ? undefined : this.questionNumber,
                questionType: this.questionType && this.questionType[0] == -1 ? undefined : this.questionType.join(','),
                typeId: this.questionCategory && this.questionCategory[0] == 0 ? undefined : this.questionCategory.join(','),
                pattern: this.questionMode == 3 ? 1 : this.questionMode,
                status: this.questionState == 0 ? undefined : this.questionState
            }).then(res => {
                this.screenNumber = res.data.total
            })
        },
        async getPaperTypeList() {
            await answerClient.getPaperTypeList({
            }).then(res => {
                res.data.forEach(e => {
                    e.name = e.typeName
                });
                res.data.unshift({
                    id: 0,
                    name: '全部题类'
                });

                this.screenList[2].data = res.data
            })
        },
        chooseScreenItem(ite, index) {
            if (index == 0) {
                // 刷题题型
                if (ite.id == -1) {
                    this.questionType = [ite.id];
                } else {
                    const all = this.questionType.findIndex((i) => i == -1);
                    if (all != -1) this.questionType.splice(all, 1);
                    const i = this.questionType.findIndex((i) => i == ite.id);
                    i == -1 ? this.questionType.push(ite.id) : this.questionType.splice(i, 1);
                    if (this.questionType.length == 0) {
                        this.questionType = [-1];
                    }
                }
                this.selectVipSubjectAnswer();
            } else if (index == 1) {
                // 刷题状态
                this.questionState = ite.id == 0 ? "" : ite.id;
                this.selectVipSubjectAnswer();
            } else if (index == 2) {
                // 刷题题类
                if (ite.id == 0) {
                    this.questionCategory = [ite.id];
                } else {
                    const all = this.questionCategory.findIndex((i) => i == 0);
                    if (all != -1) this.questionCategory.splice(all, 1);
                    const i = this.questionCategory.findIndex((i) => i == ite.id);
                    i == -1 ? this.questionCategory.push(ite.id) : this.questionCategory.splice(i, 1);
                    if (this.questionCategory.length == 0) {
                        this.questionCategory = [0];
                    }
                }
                this.selectVipSubjectAnswer();
            } else if (index == 3) {
                // 刷题模式
                this.questionMode = ite.id;
                this.questionModeName = ite.name;
                console.log(this.questionModeName, 'this.questionModeName');

            } else if (index == 4) {
                // 刷题数量
                this.questionNumber = ite.id == 0 ? "" : ite.id;
            }
        },
        close() {
            this.$emit("close");
        }

    }
}
</script>
<style lang="less" scoped>
.e-screen {
    width: 706px;
    height: 737px;
    border-radius: 16px;
    background: linear-gradient(180deg, #D6E6FF 0%, #ffffff 20%);
    position: relative;

    .e-screen-close {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20px;
        right: 24px;
        cursor: pointer;
    }

    .e-screen-title {
        font-size: 18px;
        text-align: center;
        padding-top: 24px;
        font-weight: bold;
    }

    .e-screen-number {
        margin: 28px 40px 13px;
        border: 1px solid #BDCBFF;
        border-radius: 12px;
        padding: 20px 32px 22px;
        background-color: #fff;

        .number-top {
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            .e-screen-img {
                width: 49px;
                // height: 61px;
                margin-right: 12px;
            }

            .e-screen-courseName {
                font-size: 16px;
                color: #333;
                font-weight: bold;
                flex: 1;
            }

            .number-count {
                font-size: 14px;
                color: #21385a;
                margin-left: 24px;

                span:nth-child(2) {
                    font-size: 24px;
                    font-weight: bold;
                }
            }
        }

        .number-bottom {
            display: flex;
            align-items: center;

            .e-progress-box {
                flex: 1;
                width: 0;
                height: 16px;
                border-radius: 10px;
                background-color: #EAF2FF;

                .e-progress {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .e-progress-fill {
                        display: inline-block;
                        vertical-align: top;
                        height: 100%;
                        border-radius: 10px;
                        background: linear-gradient(275deg, #4A6AF0 0%, #70A0FF 100%);
                        position: relative;

                        .e-progress-fill-text {
                            width: 100%;
                            height: 100%;
                            font-size: 14px;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                        }

                        .e-progress-icon {
                            min-width: 20px;
                            height: 20px;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            z-index: 1;
                        }
                    }

                    .e-progress-surplus {
                        flex: 1;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        white-space: nowrap;

                        span {
                            font-size: 14px;
                            color: #728FB9;
                            position: absolute;
                            left: 8%;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            .e-precentage {
                width: 40px;
                font-size: 13px;
                color: #728fb9;
                text-align: right;
            }
        }
    }

    .e-screen-list {
        height: 420px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .e-screen-choose {
        padding-left: 40px;
        margin-bottom: 16px;

        .e-screen-c-title {
            font-size: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            line-height: 1;

            .e-screen-tips-f {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #FF5050;
                margin-left: 8px;

                img {
                    width: 13px;
                    height: 13px;
                    margin-right: 6px;
                }
            }
        }

        .e-screen-c-item {
            display: inline-block;
            font-size: 15px;
            color: #333;
            padding: 8px 24px;
            border-radius: 6px;
            background-color: #f0f0f0;
            margin-right: 20px;
            margin-bottom: 20px;
            text-align: center;
            cursor: pointer;
        }

        .e-screen-c-item-check {
            background-color: #4A6AF0;
            color: #fff;
        }
    }

    .e-screen-bottom {
        width: 100%;
        height: 90px;
        box-shadow: 0px 0px 6px 1px rgba(204, 204, 204, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;

        .e-screen-but {
            width: 320px;
            height: 50px;
            border-radius: 25px;
            background: linear-gradient(180deg, #4A6AF0 0%, #4A6AF0 100%);
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
        }
    }
}
</style>
